@font-face {
  font-family: "Lora";
  src: local("Lora"),
    url("./static/font/Lora/Lora-VariableFont_wght.ttf") format("truetype");
}

@import "bootstrap/dist/css/bootstrap.min.css";

/*global*/
* {
  font-family: "Lora", serif;
}

#page-body {
  margin: auto;
  width: 80%;
  justify-content: space-between;
  margin-top: 15px;
  min-height: 90vh;
}

#page-body h2 {
  font-size: 35px;
}

nav {
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  background: #02577a;
}

/*homepage*/
.App {
  height: 100%;
}
.description,
.technology,
.creative {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description,
.creative-description {
  width: 75%;
}

.description h1,
.creative h1,
.technology h1 {
  margin-bottom: 5px;
  font-size: 50px;
}

.description a,
.creative a {
  font-size: 20px;
}

/*creative*/
.photography-container {
  margin-top: 20px;
}

/*technology*/

.tech-stack {
  font-size: 50px;
  font-weight: bold;
}

.tech-stack:hover {
  cursor: pointer;
  color: #02577a;
}

.tech-list {
  margin-top: 40px;
  list-style: none;
  padding: 0;
}

.tech-list li {
  display: inline-block;
  margin-right: 30px;
}

.hidden-tech-stack {
  height: auto;
  display: none;
  position: absolute;
  z-index: 1;
}

.tech-stack:hover + .hidden-tech-stack {
  display: block;
  font-style: italic;
  margin-bottom: 15px;
}

.technology-projects {
  margin-top: 50px;
}

.icon {
  font-size: 30px;
  color: black;
}

.project-table {
  border-collapse: collapse;
  width: 100%;
  width: 90%;
}

.year th {
  width: 30px;
}

.project-table li {
  display: inline-block;
  margin-right: 10px;
}

.project-table ul {
  padding: 0;
}

.project-table th:first-child {
  width: 5%;
}

.project-table th:nth-child(2),
.project-table th:nth-child(3) {
  width: 45%;
}

.project-table th,
.project-table td {
  text-align: left;
  padding: 8px 16px;
  border: 1px solid #ddd;
}

.project-table th {
  background-color: #eee;
}

.social-media {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

/* contact us */
.contact-us {
  margin-top: 40vh;
  display: flex;
  flex-direction: row;
  text-align: center;
}

.contact-us-content p {
  margin: 0;
}

.contact-us > div {
  flex: 1;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(52, 152, 219, 0.3);
}

.contact-us-content {
  height: auto;
  min-height: 30vh;
}

.connect-to-email {
  background-color: #02577a;
  color: #f5f5f5;
  height: 30vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-linkedin {
  margin-right: 15px;
}
.icon :hover {
  cursor: pointer;
  color: #f5f5f5;
  transition: 0.3s ease-in-out;
}

.title {
  margin-top: 30px;
}

/*footer */
.footer {
  background-color: #02577a;
  color: #f5f5f5;
  margin-top: 50px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer p {
  margin: 0;
  padding: 0;
}
