/* component.css */
.carousel-image-container {
  position: relative;
  width: 100%;
  height: 100%; /* Set a fixed height for the carousel items */
  overflow: hidden;
  margin: 0 0 30px 0;
}

.carousel-image {
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  width: 100%;
  height: 1000px;
}

.photo-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portrait-card {
  width: 30%;
  height: 600px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.portrait-card:hover,
.portrait-card-horizontal:hover {
  transform: scale(1.05);
}

.portrait-card-horizontal {
  width: 600px;
  height: 600pxsa;
  object-fit: cover;
  transition: transform 0.5s ease;
}
